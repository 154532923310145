import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import formStore from '../../stores/FormsStore'; // Adjust to match your actual store path

const LeadMagnetForm: React.FC = observer(() => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    // Call your MobX store to send data to the backend
    await formStore.submitForm({
      form_name: "Lead Magnet: 3-Minute Coverage Gap Check",
      data: {
        name,
        email
      }
    });

    // Clear fields (optional)
    setName('');
    setEmail('');
  };

  return (
    <div className="relative font-maleo min-h-screen w-full">
      {/* Background Image or Color */}
      <img 
        src="https://bay-and-basin-bucket.s3.amazonaws.com/Waves.webp" 
        alt="Waves Background" 
        className="absolute inset-0 w-full h-full object-cover" 
      />

      {/* Centered Container */}
      <div className="absolute inset-0 flex items-center justify-center p-4">
        <div 
          className="bg-white p-8 rounded-lg border-4 border-sea-blue shadow-lg w-full max-w-sm"
          style={{ borderRadius: '30px' }}
        >
          {/* Title */}
          <h1 className="font-maleo text-2xl text-sea-blue tracking-wider mb-4">
            The 3-Minute Coverage Gap Check
          </h1>

          {/* Subheading / Intro */}
          <p className="font-acumin text-base text-sea-blue mb-3">
            Discover the <span className="font-bold">5 Must-Have Protections</span> most brokers
            overlook. Enter your email below and we'll send you a quick, 3-minute read to help
            you spot hidden risks and strengthen your coverage—fast.
          </p>

          {/* 5 Coverage Items as Single-Bullet Titles */}
          <ul className="list-disc list-inside text-sea-blue mb-6">
            <li>Cyber & Data Liability</li>
            <li>Business Interruption Coverage</li>
            <li>Up-to-Date Property Valuations</li>
            <li>Tailored Workers’ Comp Enhancements</li>
            <li>Health Benefits That Attract Talent</li>
          </ul>

          {/* Form */}
          <form onSubmit={handleSubmit} className="flex flex-col items-center">
            <input
              type="text"
              placeholder="Name"
              className="w-full mb-2 p-2 border rounded text-lg focus:outline-none focus:ring-2 focus:ring-wave-blue"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="Email"
              className="w-full mb-4 p-2 border rounded text-lg focus:outline-none focus:ring-2 focus:ring-wave-blue"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            
            <button 
              type="submit" 
              className="bg-sea-blue text-white py-2 px-10 rounded-full font-light transition-colors duration-300 hover:bg-wave-blue"
            >
              Send Me The Checklist
            </button>
          </form>
        </div>
      </div>
    </div>
  );
});

export default LeadMagnetForm;