import React from "react";
import { ReactComponent as LogoWatermark } from "../../assets/images/Logo-Watermark.svg";
import SideCloudBubble from "../utilities/SideCloudBubble";
import CircleBubble from "../utilities/CircleBubble";

const FirstRow: React.FC = () => {
  return (
    <div className="flex flex-col md:flex-row items-center relative px-8 py-8">
      {/* LEFT / TEXT SECTION */}
      <div className="w-full md:w-1/3 px-4 md:pl-24 py-8 text-center md:text-left">
        <h1 className="text-4xl font-light text-[#FDAB5D] mb-4 tracking-[.25em] leading-snug">
          We work for <br />
          <span className="font-regular tracking-[.25em]">YOUR BUSINESS</span>
        </h1>
        <p className="text-md text-[#0077b6] leading-relaxed tracking-[.2em]">
          We are an independent, California women-owned business serving the needs of our
          clients across our region and beyond. We focus on providing you with the best
          insurance and risk management your company needs, especially as your business
          continues to grow. Our team is dedicated to obtaining insurance offerings from
          multiple, trusted insurance companies so you have options. We work for you!
        </p>
      </div>

      {/* RIGHT / IMAGE SECTION */}
      <div className="w-full md:w-2/3 flex justify-center md:justify-end">
        {/* Restrict max width if you want it smaller on large screens */}
        <div className="w-full max-w-lg">
          <SideCloudBubble
            imageUrl="https://bay-and-basin-bucket.s3.us-west-1.amazonaws.com/AboutUsSide1.webp"
            clipPath="SideCloudBubble-AboutUs-Clip"
          />
        </div>
      </div>
    </div>
  );
};

const NextRow: React.FC = () => {
  return (
    <div className="flex flex-col md:flex-row items-center relative px-8 py-8">
      {/* LEFT / IMAGE SECTION */}
      <div className="w-full md:w-2/3 flex justify-center md:justify-start">
        <div className="w-full max-w-lg">
          <CircleBubble
            imageUrl="https://bay-and-basin-bucket.s3.us-west-1.amazonaws.com/personalized-service.webp"
            clipPath="circle-personalized-clip"
          />
        </div>
      </div>

      {/* RIGHT / TEXT SECTION */}
      <div className="w-full md:w-1/3 px-4 md:pr-24 py-8 text-center md:text-left">
        <h1 className="text-4xl font-light text-[#FDAB5D] mb-4 tracking-[.25em] leading-snug">
          Personalized
          <br />
          <span className="font-regular tracking-[.25em]">CUSTOMER SERVICE</span>
        </h1>
        <p className="text-md text-[#0077b6] leading-relaxed tracking-[.2em]">
          Excellent customer service and face-to-face communication is a high priority for
          us. You'll never deal with 1-800 numbers or frustrating automated messages when
          you work with our team. We believe that protecting every aspect of your business
          is a collaborative venture, so we want to get to know you and your business
          practice. Whether it’s a new policy or just a simple question, we want to be your
          quick, reliable source for all things insurance.
        </p>
      </div>
    </div>
  );
};

const TeamPhotoSection: React.FC = () => {
  return (
    <div className="relative p-8">
      <div className="relative w-full">
        {/* Team Photo (always visible, scales down with w-full) */}
        <img
          src="https://bay-and-basin-bucket.s3.amazonaws.com/TeamPhoto.webp"
          alt="Our Team"
          className="w-full h-auto"
        />

        {/* Overlayed text box on large screens */}
        <div
          className="absolute top-0 left-0 w-1/4 p-4 hidden lg:block"
          style={{ background: "rgba(255, 255, 255, 0.6)" }}
        >
          <h2 className="text-4xl text-sea-blue mb-4 text-left leading-tight">
            Our Mission
          </h2>
          <p className="text-md text-sea-blue text-left leading-normal">
            To provide our clients with the best coverage, at a competitive price with
            professional counsel in the Risk Management process. We want our clients to be
            able to focus on serving their clients’ needs while knowing their insurance
            program is there to step in as needed. We do this in a positive, fun, fast-paced
            work environment, offering flexible work options and growth opportunities for
            our employees.
          </p>
        </div>
      </div>
    </div>
  );
};

const TeamMemberSection: React.FC = () => {
  return (
    <div className="pt-24 pl-8">
      <h1 className="text-4xl font-maleo tracking-[.3em] text-sea-blue">Our TEAM</h1>
      <div className="text-sea-blue px-12">
        <div className="flex flex-col md:flex-row items-center md:items-center">
          <div className="md:w-1/2 flex flex-col items-center md:items-start">
            {/* Title and Details */}
            <h2 className="text-5xl font-regular text-sunset-light-orange mb-2">
              <span className="tracking-[.25em]">
                TINA
                <br />
                BARLOCKER
              </span>
            </h2>
            <p className="text-md text-sunset-dark-orange mb-4 relative">
              CIC CRM CAWC
              <br />
              831-771-5344
              <br />
              tbarlocker@bayandbasinins.com
              <br />
              Bay and Basin Insurance Services
            </p>
            <div className="absolute" style={{ width: "44%", height: "auto" }}>
              <LogoWatermark />
            </div>
          </div>
          <div className="md:w-2/3 flex justify-end relative">
            <CircleBubble
              imageUrl="https://bay-and-basin-bucket.s3.amazonaws.com/tina-2022.webp"
              clipPath="tina-clip"
              scale={0.2}
              translateX={-300}
              profile={true}
            />
          </div>
        </div>
        <div className="text-lg text-left mb-12">
          <p className="mb-4">
            Tina has been in the insurance business for nearly 35 years. For 13 years, Tina
            ran the Barlocker Insurance office in Salinas, CA owned by The Leavitt Group.
            Two years ago, Tina bought the business and created Bay and Basin Insurance
            Services.
          </p>
          <p className="mb-4">
            Tina began her insurance career as an Underwriter for Kemper Insurance in San
            Francisco. She then went to work as a Producer for an international brokerage
            firm - Arthur J. Gallagher, also in San Francisco. Eventually, she joined the
            family business and went to work for Barlocker Insurance. Tina enjoys finding
            solutions to business challenges and appreciates helping businesses with the use
            of insurance products and/or Risk Management solutions.
          </p>
          <p>
            A true believer of continually improving and educating yourself, Tina has three
            designations – CIC – Certified Insurance Counselor; CRM – Certified Risk
            Manager, and CAWC – Certified Authority on Workers’ Compensation. She welcomes
            you to reach out so she can assist you with your business and/or employee
            benefit needs.
          </p>
        </div>
      </div>
    </div>
  );
};

const TeamMemberSectionOscar: React.FC = () => {
  return (
    <div className="pt-24 pr-8">
      <div className="text-sea-blue px-12">
        <div className="flex flex-col md:flex-row-reverse items-center md:items-center">
          <div className="md:w-1/2 flex flex-col items-center md:items-end">
            {/* Title and Details for Oscar */}
            <h2 className="text-5xl font-regular text-sunset-light-orange mb-2">
              <span className="tracking-[.25em]">
                OSCAR
                <br />
                HUERTA
              </span>
            </h2>
            <p className="text-md text-sunset-dark-orange mb-4 relative">
              CISR, AFIS
              <br />
              831-771-5325
              <br />
              ohuerta@bayandbasinins.com
              <br />
              Bay and Basin Insurance Services
            </p>
            <div className="absolute right-40" style={{ width: "33%", height: "auto" }}>
              <LogoWatermark />
            </div>
          </div>
          <div className="md:w-2/3 flex justify-start relative">
            <CircleBubble
              imageUrl="https://bay-and-basin-bucket.s3.amazonaws.com/Oscar.webp"
              clipPath="oscar-clip"
              scale={0.25}
              translateX={-500}
              translateY={100}
              profile={true}
              blue={true}
            />
          </div>
        </div>
        <div className="text-lg text-right mb-12">
          <p className="mb-4">
            Oscar has been working in the insurance industry for nearly 20 years, providing
            a variety of property and casualty products and services that can help protect
            your business. A native of Salinas California, Oscar started his career in 2005
            with Barlocker Insurance Services as a commercial lines assistant. He has since
            earned his Certified Insurance Service Representative (CISR) and Agribusiness
            and Farm Insurance Specialist (AFIS) designations.
          </p>
          <p>
            He is now a Producer and Senior Account Manager for Bay and Basin Insurance
            Services. Oscar is bilingual and ready to assist you with all your insurance
            needs.
          </p>
        </div>
      </div>
    </div>
  );
};

const AboutUs: React.FC = () => {
  return (
    <div>
      <FirstRow />
      <NextRow />
      <TeamPhotoSection />
      <TeamMemberSection />
      <TeamMemberSectionOscar />
    </div>
  );
};

export default AboutUs;