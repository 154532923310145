import React, { useState } from "react";
import formStore from "../../stores/FormsStore";
import { observer } from "mobx-react-lite";

interface FormData {
    name: string;
    email: string;
    phone: string;
    insuranceType: string; // Assuming this is what you meant by "selectValue"
    businessName: string;
}

const QuoteForm: React.FC = observer(() => {
    const [formData, setFormData] = useState<FormData>({
        name: "",
        email: "",
        phone: "",
        insuranceType: "",
        businessName: ""
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Assume formStore.submitForm is defined somewhere in your context or imported from a store
        await formStore.submitForm({
            form_name: "Request for an Online Quote",
            data: formData
        });
        setFormData({
            name: "",
            email: "",
            phone: "",
            insuranceType: "",
            businessName: ""
        })
        console.log('Form data submitted:', formData);
    };

    return (
        <div className="p-4 bg-white border-4 border-sea-blue-600 rounded-lg shadow-lg max-w-md mx-auto my-4 sm:p-6 sm:max-w-xl lg:max-w-2xl">
            <h3 className="text-3xl font-light text-sea-blue mb-6 text-center text-balance">
                GET A QUOTE ONLINE!
            </h3>
            <form className="flex flex-col items-center w-full" onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    required
                    onChange={handleChange}
                    className="w-full mb-2 p-2 border rounded tracking-widest text-lg"
                />
                <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full mb-2 p-2 border rounded tracking-widest text-lg"
                />
                <input
                    type="tel"
                    placeholder="Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full mb-2 p-2 border rounded tracking-widest text-lg"
                />
                <input
                    type="text"
                    placeholder="Business Name"
                    name="businessName"
                    value={formData.businessName}
                    required
                    onChange={handleChange}
                    className="w-full mb-2 p-2 border rounded tracking-widest text-lg"
                />
                <label className="self-start text-sea-blue text-lg font-semibold w-full">
                    Insurance Requested
                </label>
                <select
                    name="insuranceType"
                    className="w-full mb-4 p-2 border rounded text-lg"
                    value={formData.insuranceType}
                    onChange={handleChange}
                    style={{ color: formData.insuranceType ? '#3C5572' : 'grey' }}
                    required
                >
                    <option value="" disabled>Select</option>
                    <option value="Business">Business</option>
                    <option value="Life">Life</option>
                </select>
                <button
                    type="submit"
                    className={`bg-sea-blue text-white py-2 px-12 rounded-full font-light text-center w-full sm:w-auto ${formStore.loading ? "opacity-50 cursor-not-allowed" : ""}`}
                    disabled={formStore.loading}
                >
                    {formStore.loading ? "LOADING..." : "REQUEST QUOTE"}
                </button>
            </form>
        </div>
    );
});

export default QuoteForm;
