import React, { Suspense } from 'react';
import { AnimatePresence } from 'framer-motion';
import Root from './components/Root';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Blog from './components/Blog';
import BlogPostPage from './components/Blog/BlogPostPage';
import ContactUs from './components/ContactUs';
import InsuranceServices from './components/InsuranceServices';
import BusinessInsurance from './components/InsuranceServices/BusinessInsurance';
import LifeAndMore from './components/InsuranceServices/LifeAndMore';
import DisplayInsurance from './components/InsuranceServices/DisplayInsurance';
import Hiring, { HiringIndexPage } from './components/Hiring';
import CommercialLinesAccountManager from './components/Hiring/CommercialLinesAccountManager';
import CommercialLinesSalesRep from './components/Hiring/CommercialLinesSalesRepresentative';
import Carriers from './components/CarrierPartners';
import ClientPortal from './components/ClientPortal';
import TermsOfService from './components/TermsofService';
import LeadMagnetForm from './components/LeadMagnet';

const App = () => {
  return (
    <Root>
      {/* Wrap the routes with Suspense and provide a fallback for loading state */}
        <Suspense fallback={<div>Loading...</div>}>
          <AnimatePresence mode="wait">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path={'/home'} element={<Home/>} />
              <Route path={'/about-us'} element={<AboutUs/>} />
              <Route path={'/blog'} element={<Blog/>} />
              <Route path={'/contact-us'} element={<ContactUs/>} />
              <Route path={'/blog/:option'} element={<BlogPostPage />}/>
              <Route path={'/more-info'} element={<LeadMagnetForm />}/>
              <Route path={'/hiring'} element={<Hiring />}>
                <Route path={""} element={<HiringIndexPage />} />
                <Route path={"commercial-lines-account-manager"} element={<CommercialLinesAccountManager />} />
                <Route path={"commercial-lines-sales-representative"} element={<CommercialLinesSalesRep />} />
              </Route>
            {/* Define a parent route for InsuranceServices */}
            <Route path="/insurance-services" element={<InsuranceServices />}>
              {/* Define child routes for BusinessInsurance and LifeAndMore */}
              <Route path="business-insurance" element={<BusinessInsurance />} />
              <Route path="life-and-more" element={<LifeAndMore />} />
            </Route>
            <Route path="/insurance-services/business-insurance/:option" element={<DisplayInsurance />} />
            <Route path="/insurance-services/life-and-more/:option" element={<DisplayInsurance />} />

            <Route path="/detailed-insurance" element={<DisplayInsurance/>}/>
            <Route path="/carrier-partners" element={<Carriers/>} />
            <Route path="/client-portal" element={<ClientPortal/>} />
            <Route path="/terms-of-service" element={<TermsOfService/>} />
            
          </Routes>
          </AnimatePresence>
        </Suspense>
    </Root>
  );
};


export default App;
